nav{
    background: rgba(69, 90, 91, 0.3);
    width: 100%;
    display: block;
    padding: 1.5rem 2rem;
    z-index: 2;
    position: fixed;
    display: flex;
    gap: 0.8rem;
    backdrop-filter: blur(15px);
    height: 4.25rem;
}

nav a{
    background: transparent;
    padding-inline: 0.5rem;
    border-radius: 50%;
    color: var(--color-light);
    font-size: 1.1rem;
    transition: var(--transition);
    text-align: center;
}

nav a:hover{
    color: var(--color-primary);
}

nav a.active{
    color: var(--color-primary);
}

h1{
    padding-right: 24rem;
}

/*nav{
    position: fixed;
    width: 7rem;
    background-color: rgb(37, 35, 35);
    text-align: center;
    align-items: center;
    top: 0;
    left: 0;
    height: 100%;
    
}*/

/*nav h1{
    padding-top: 2rem;
    margin-bottom: 10.5rem;
}*/

/*nav a {
    display: block;
    gap: 10rem;
    padding: 0.7rem;
    text-decoration: none;
    color: #ffffff; 
}*/

/*hr{
    border: 0.1px solid #585353;
    width: 7rem;
}*/

/*nav{
    background: rgba(69, 90, 91, 0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}*/

