header {
    height: 90vh;
    padding-top: 2rem;
    overflow: hidden;
}

.header__container {
    text-align: left;
    height: 100%;
    position: relative;
}

.h1_text{
    font-weight: 700;
    margin-left: 4rem;
    font-size: 4rem;
}
/* CTA */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: left;
    margin-left: 4rem;
    align-items: center;
}

/* Scroll Down Left Side */
.scroll__down_left{
    position: absolute;
    left: -12.5rem;
    bottom: 17.5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
    margin-left: 0rem;
    color: var(--color-primary);
    
}

/* Scroll Down Right Side */
.scroll__down_right{
    position: absolute;
    right: -12.5rem;
    bottom: 17.5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
    margin-left: 0rem;
    color: var(--color-primary);
}

/* Media Queries(Medium Devices) */
@media screen and (max-width: 1024px){
    header{
        height: 68vh;
    }
}

/* Media Queries(Medium Devices) */
@media screen and (max-width: 600px){
    header{
        height: 100vh;
    }

    .header__socials,
    .scroll__down{
        display: none;

    }
}

/* Header Socials 
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;  
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-light);
}*/

/* Image: Me */
/*
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 2rem 1.5rem 1rem;
}
*/
