@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #0D1321;
    --color-bg-variant: #1D2D44;
    --color-primary: #0daec0;
    --color-primary-variant: #748CAB;
    --color-white: #fff;
    --color-light: #F0EBD8;

    --transition: all 400ms ease;
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

}

html {
    scroll-behavior: smooth;    
}

::-webkit-scrollbar{
    display: none;
}

body {
    font-family: 'Courier Prime', monospace;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.3;
}

/* General Styles */

.container{
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5{
    font-weight: 500;
}

section{
    margin-top: 10rem;
    margin-bottom: 10rem;
    margin-left: 0rem;
}

/*section > h2, section > h5{
    text-align: left;
    font-size: 3rem;
    color: var(--color-light);
}*/

.h2_text{
    font-size: 2rem;
    margin-left: 4rem;
    color: var(--color-primary);
    font-weight: 700;
}

.h4_tag{
    color: var(--color-light);
    margin-left: 4rem;
}

.body_tag{
    color: var(--color-light);
    margin-left: 2rem;
}

.html_tag{
    color: var(--color-light);
    margin-left: 0rem;
}

.p_text{
    margin-left: 6rem;
}

/*section > h2, section > h4{
    color: var(--color-primary);
    margin-bottom: 1rem;
}*/

.text-light{
    color: var(--color-light);
}

a{
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover{
    color: var(--color-white);
}

.btn{
    width: 12rem;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    text-align: center;
    font-size: large;
}

.btn:hover{
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary{
    background: var(--color-primary);
    color: var(--color-bg);
}

img{
    display: block;
    width: 100%;
    object-fit: cover;
}

/* Media Queries(Medium Devices) */
@media screen and (max-width: 1024px) {
    .container{
        width: var(--container-width-md);
    }

    section{
        margin-top: 6rem;
    }
}

/* Media Queries(Small Devices) */
@media screen and (max-width: 600px) {
    .container{
        width: var(--container-width-sm);
    }

    section > h2{
        margin-bottom: 2rem;
    }
}